import { Tooltip } from '@mui/material';
import VideocamTwoToneIcon from '@mui/icons-material/VideocamTwoTone';

export const cameraSize = 16;
export const activeScale = 1.5; // This will make the active icon 50% larger

export const CameraIcon = ({ camera, xVal, yVal, theme, isActive, onClick, isFlipped }) => {
  const cameraAngle = isFlipped ? 360 - camera.angle + 180 : 360 - camera.angle;

  return (
    <Tooltip title={camera.name} arrow>
      <VideocamTwoToneIcon
        onClick={onClick}
        style={{
          position: 'absolute',
          left: xVal || 0,
          bottom: yVal || 0,
          transform: `rotate(${cameraAngle}deg) scale(${isActive ? activeScale : 1})`,
          width: cameraSize,
          height: cameraSize,
          cursor: 'pointer',
          color: isActive ? theme.palette.error.main : '#888888',
          transition: 'all 0.3s ease', // Smooth transition for both color and size
          transformOrigin: 'center', // Makes the scaling happen from the center
        }}
      />
    </Tooltip>
  );
};