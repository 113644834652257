import { useTheme } from '@mui/material';
import { AutoIsoObjectType } from '../types/AutoIsoModel';
import { makeStyles } from '@mui/styles';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { useMemo } from 'react';
import { getTeamColor } from '@/features/teams/functions/getTeamColor';

function roundUpToNDecimalPlaces(num: number, decimalPlaces: number): number {
  const multiplier = 10 ** decimalPlaces;
  return Math.ceil(num * multiplier) / multiplier;
}

function getContrastColor(hex: string): string {
  // Remove the '#' if it's included
  hex = hex.replace('#', '');

  // Parse the hex color to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the luminance of the color
  const luminance = 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  // Return white or black based on luminance threshold
  return luminance > 0.5 ? 'black' : 'white';
}

const useStyles = makeStyles(() => ({
  obj: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'black',
  },
}));

type Props = {
  isFlipped: boolean;
  awayTeam: any;
  elementSize: { width: number; height: number };
  entity: any;
  objectOfPlay: string;
  pos: any;
  homeTeam: any;
  setTop: any;
  surfaceHeight: number;
  surfaceWidth: number;
  top: number;
  game : GameModel;
};

export const TrackedEntity = ({
  isFlipped,
  awayTeam,
  elementSize,
  entity,
  objectOfPlay,
  pos,
  homeTeam,
  setTop,
  surfaceHeight,
  surfaceWidth,
  top,
  game
}: Props) => {
  const { singleCurrentlyTrackedObjectId, trackEntity } = useServerStateContext();
  const isTracked = entity.id.toString() === singleCurrentlyTrackedObjectId;
  const theme = useTheme();
  const styles = useStyles();

  const label = useMemo(
    () =>
      entity.type === AutoIsoObjectType.Player || entity.type === AutoIsoObjectType.Official
        ? entity.playerNumber
        : objectOfPlay?.charAt(0).toUpperCase(),
    [objectOfPlay, entity.playerNumber, entity.type],
  );

  const objectSize = useMemo(() => {
    return entity.type === AutoIsoObjectType.Player || entity.type === AutoIsoObjectType.Official
      ? 24
      : 15;
  }, [entity.type]);


  
  const backgroundColor = useMemo(() => {
    // If this is the currently tracked object, return white
    if (entity.id === singleCurrentlyTrackedObjectId) {
      return '#ffffff'; 
    }
  
    // Handle players
    if (entity.type === AutoIsoObjectType.Player) {
      const isHomeTeam = entity.playerTeamId === homeTeam.id;
      const team = isHomeTeam ? homeTeam : awayTeam;

      const fallbackColor = isHomeTeam ? theme.palette.info.main : theme.palette.success.main;
      
      return getTeamColor(team, game, fallbackColor);
    }
  
    // Handle officials and other entities
    return entity.type === AutoIsoObjectType.Official 
      ? theme.palette.grey[700] 
      : theme.palette.primary.main;
  }, [entity, homeTeam, awayTeam, singleCurrentlyTrackedObjectId, theme.palette]);

  const color = useMemo(() => {
    return getContrastColor(backgroundColor);
  }, [backgroundColor]);

  const flipCoef = isFlipped ? -1 : 1;
  const objectLabelFontSize = objectSize / 2;
  const xPercent = ((pos.x * flipCoef) / (surfaceWidth / 2) + 1) / 2.0;
  const yPercent = ((pos.y * flipCoef) / (surfaceHeight / 2) + 1) / 2.0;
  const xVal = (elementSize.width - objectSize) * xPercent;
  const yVal = (elementSize.height - objectSize) * yPercent;
  const zIndex =
    entity.id === 1 ? 10000 : isTracked || top === entity.id ? 1000 : entity.playerNumber;

  const left: number = useMemo(() => roundUpToNDecimalPlaces(xVal, 0), [xVal]);
  const bottom: number = useMemo(() => roundUpToNDecimalPlaces(yVal, 0), [yVal]);

  return (
    <div
      className={styles.obj}
      style={{
        cursor: 'pointer',
        width: objectSize,
        height: objectSize,
        borderRadius: objectSize / 2,
        backgroundColor,
        left,
        bottom,
        fontSize: objectLabelFontSize,
        color: color,
        border: `1px solid ${color}`,
        zIndex,
        boxShadow: '0px 0px 5px 5px #00000022',
        opacity: isTracked || top === entity.id ? 1 : 0.8,
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
        trackEntity(entity, `Virtual surface`);
      }}
      onMouseOver={() => setTop(entity.id)}
      key={pos.id}
    >
      {label}
    </div>
  );
};
