import { useState, useEffect, useMemo } from 'react';
import { Box, Button, TextField, Typography, Paper, Grid, Alert, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import VideocamIcon from '@mui/icons-material/Videocam';
import { JsonEditor, githubDarkTheme } from 'json-edit-react';
import { CamSpec } from '@/types/camspec';

type Props = {
  width?: number;
  height?: number;
  backgroundSvg?: string;
  scale?: number;
  initialCamSpec: CamSpec;
};

const Arena = ({
  width = 800, // Real width in feet/meters
  height = 600, // Real height in feet/meters
  backgroundSvg,
  scale = 5, // Scale factor for display
  initialCamSpec,
}: Props) => {
  const [camspec, setCamSpecs] = useState<CamSpec>();

  type ArenaCamera = {
    id: number;
    index: number;
    name: string;
    x: number;
    y: number;
    z: number;
    rotation: number;
    tilt: number;
    ccu: string;
  };

  const [cameras, setCameras] = useState<ArenaCamera[]>([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  // Display dimensions (scaled up for better visibility)
  const displayWidth = width * scale;
  const displayHeight = height * scale;

  const ROTATION_STEP = 5;
  const FORWARD_DISTANCE = 20; // Real-world units
  const MIN_TILT = -45;
  const MAX_TILT = 45;

  useEffect(() => {
    if (!initialCamSpec) return;
    // Transform all cameras in one go
    const newCameras = initialCamSpec.cams.map((cam, idx) => ({
      id: idx,
      index: idx,
      name: cam.displayName,
      x: cam.position.x,
      y: cam.position.y,
      z: cam.position.z,
      rotation: cam.rotation,
      tilt: cam.tilt,
      ccu: cam.ccu,
    }));

    // Single state update with all cameras
    setCameras(newCameras);
  }, [initialCamSpec]);

  useEffect(() => {
    const newCamSpec = {
      units: camspec?.units || 'feets',
      trackingSpec: camspec?.trackingSpec || {},
      cams: cameras.map((cam) => {
        const forward = calculateForwardPoint(cam);
        return {
          displayName: cam.name,
          position: { x: cam.x, y: cam.y, z: cam.z },
          forward,
          rotation: cam.rotation,
          tilt: cam.tilt,
          ccu: cam.ccu,
        };
      }),
    };
    setCamSpecs(newCamSpec);
  }, [cameras]);

  // Convert between real and display coordinates
  const toDisplayCoords = (x, y) => ({
    x: x * scale,
    y: y * scale,
  });

  const toRealCoords = (x, y) => ({
    x: x / scale,
    y: y / scale,
  });

  // Calculate forward point based on camera position and rotation
  const calculateForwardPoint = (camera) => {
    const angleRadians = camera.rotation * (Math.PI / 180);
    return {
      x:  (FORWARD_DISTANCE * Math.cos(angleRadians))/20,
      y:  (FORWARD_DISTANCE * Math.sin(angleRadians))/20,
    };
  };

  // Handle keyboard events for rotation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') return;
      if (!selectedCamera) return;

      setCameras(
        cameras.map((cam) => {
          if (cam.id !== selectedCamera.id) return cam;

          let newRotation = cam.rotation;
          if (e.key === 'ArrowLeft') {
            newRotation = (cam.rotation - ROTATION_STEP) % 360;
          } else if (e.key === 'ArrowRight') {
            newRotation = (cam.rotation + ROTATION_STEP) % 360;
          }
          return { ...cam, rotation: newRotation };
        }),
      );
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [cameras, selectedCamera]);

  // Convert canvas coordinates to centered coordinate system
  const toCanvasCoords = (x: number, y: number) => ({
    x: x - displayWidth / 2,
    y: displayHeight / 2 - y,
  });

  // Convert centered coordinates to canvas coordinates
  const fromCanvasCoords = (x: number, y: number) => ({
    x: x + displayWidth / 2,
    y: displayHeight / 2 - y,
  });

  // Handle adding a new camera
  const handleAddCamera = () => {
    const newIndex = cameras.length;
    const newCamera = {
      id: Date.now(),
      index: newIndex,
      name: `Camera ${newIndex + 1}`,
      x: 0,
      y: 0,
      z: 0,
      rotation: 0,
      tilt: 0,
      ccu: `CCU_${newIndex + 1}`,
    };
    setCameras([...cameras, newCamera]);
  };

  const handleDragStart = (e, camera) => {
    // Only start dragging if we're clicking the camera icon itself
    if (e.target.closest('.MuiTextField-root') || e.target.closest('button')) {
      return;
    }
    setSelectedCamera(camera);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    // Don't initiate drag if we're interacting with inputs or buttons
    if (e.target.closest('.MuiTextField-root') || e.target.closest('button')) return;
    if (!isDragging || !selectedCamera) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const centeredCoords = toCanvasCoords(x, y);
    const realCoords = toRealCoords(centeredCoords.x, centeredCoords.y);

    setCameras(
      cameras.map((cam) =>
        cam.id === selectedCamera.id ? { ...cam, x: realCoords.x, y: realCoords.y } : cam,
      ),
    );
  };

  const handleMouseUp = (e) => {
    // Don't reset dragging if we're interacting with inputs
    if (e.target.closest('.MuiTextField-root') || e.target.closest('button')) return;
    setIsDragging(false);
  };

  const updateCamSpecs = (e) => {
    //setCamSpecs(e);
  };

  const handleFieldChange = (camera, field, value) => {
    setCameras(
      cameras.map((cam) => {
        if (cam.id !== camera.id) return cam;

        let updatedValue = value;
        if (field === 'tilt') {
          const numValue = parseFloat(value);
          if (isNaN(numValue)) return cam;
          updatedValue = Math.max(MIN_TILT, Math.min(MAX_TILT, numValue));
        }

        return { ...cam, [field]: updatedValue };
      }),
    );
  };

  const handleDeleteCamera = (cameraToDelete) => {
    const updatedCameras = cameras
      .filter((cam) => cam.id !== cameraToDelete.id)
      .map((cam, idx) => ({
        ...cam,
        index: idx,
        name: cam.name.startsWith('Camera ') ? `Camera ${idx + 1}` : cam.name,
      }));

    setCameras(updatedCameras);
    if (selectedCamera?.id === cameraToDelete.id) {
      setSelectedCamera(null);
    }
  };

  // Styled Components
  const ArenaContainer = styled(Box)(({ theme }) => ({
    maxWidth: '1200px',
    margin: '0 auto',
    padding: theme.spacing(2),
  }));

  const Canvas = styled(Box)(({ theme }) => ({
    margin: '50px',
    position: 'relative',
    border: `2px solid ${theme.palette.grey[300]}`,
    backgroundColor: 'white',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }));

  const CameraIcon = styled(Box)(({ theme, isSelected }) => ({
    position: 'absolute',
    cursor: 'move',
    transform: 'translate(-50%, -50%)',
    ...(isSelected && {
      outline: `2px solid ${theme.palette.primary.main}`,
      outlineOffset: '2px',
      borderRadius: '50%',
    }),
  }));

  const InfoPanel = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    padding: theme.spacing(2),
    width: '350px',
    zIndex: 100,
    boxShadow: theme.shadows[3],
  }));

  const backgroundImage = useMemo(() => {
    if (backgroundSvg) {
      const svgBase64 = btoa(backgroundSvg.trim());
      return `url("data:image/svg+xml;base64,${svgBase64}")`;
    }
    return 'none';
  }, [backgroundSvg]);

  return (
    <ArenaContainer>
      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCamera}
          startIcon={<VideocamIcon />}
        >
          Add Camera
        </Button>
        <Alert severity="info" sx={{ mt: 1 }}>
          Use left and right arrow keys to rotate the selected camera
        </Alert>
      </Box>

      <Canvas
        sx={{
          width: displayWidth,
          height: displayHeight,
          backgroundImage,
        }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {/* Origin crosshair */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '1px',
            height: '1px',
            '&::before': {
              content: '""',
              position: 'absolute',
              width: '24px',
              height: '1px',
              backgroundColor: 'grey.400',
              left: '-12px',
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              height: '24px',
              width: '1px',
              backgroundColor: 'grey.400',
              top: '-12px',
            },
          }}
        />

        {/* Cameras */}
        {cameras.map((camera) => {
          const displayCoords = toDisplayCoords(camera.x, camera.y);
          const { x, y } = fromCanvasCoords(displayCoords.x, displayCoords.y);
          const forward = calculateForwardPoint(camera);
          const forwardDisplay = toDisplayCoords(forward.x, forward.y);
          const forwardCanvas = fromCanvasCoords(forwardDisplay.x, forwardDisplay.y);
          const isSelected = selectedCamera?.id === camera.id;

          return (
            <Box key={camera.id}>
              <CameraIcon
                sx={{
                  left: `${x}px`,
                  top: `${y}px`,
                  transform: `translate(-50%, -50%) rotate(${camera.rotation}deg)`,
                }}
                onMouseDown={(e) => handleDragStart(e, camera)}
              >
                <VideocamIcon color="primary" />
              </CameraIcon>

              <InfoPanel
                elevation={5}
                sx={{
                  left: `${x-350}px`,
                  top: `${y+10}px`,
                  display: isSelected ? 'block' : 'none',
                  zIndex: isSelected ? 1000 : 'auto',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Position (real coordinates):</Typography>
                    <Typography variant="body2">X: {camera.x.toFixed(2)}</Typography>
                    <Typography variant="body2">Y: {camera.y.toFixed(2)}</Typography>
                    <Typography variant="body2">Rotation: {camera.rotation.toFixed(1)}°</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Forward (real coordinates):</Typography>
                    <Typography variant="body2">X: {forward.x.toFixed(2)}</Typography>
                    <Typography variant="body2">Y: {forward.y.toFixed(2)}</Typography>
                  </Grid>
                </Grid>
              </InfoPanel>

              {/* Forward direction line */}
              <svg
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  pointerEvents: 'none',
                }}
              >
                <line
                  x1={x}
                  y1={y}
                  x2={forwardCanvas.x}
                  y2={forwardCanvas.y}
                  stroke={isSelected ? '#1976d2' : '#90caf9'}
                  strokeWidth={isSelected ? '2' : '1'}
                  strokeDasharray="4"
                />
              </svg>
            </Box>
          );
        })}
      </Canvas>

      {camspec && (
        <JsonEditor
          data={camspec}
          setData={(e) => {
            updateCamSpecs(e);
          }}
          collapse={false}
          theme={githubDarkTheme}
        />
      )}
    </ArenaContainer>
  );
};

export default Arena;
