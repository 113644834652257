import * as React from 'react';
import MoreHorIcon from '@mui/icons-material/MoreHoriz';

import { Divider, IconButton, Menu, MenuItem, Tooltip, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { usePlaybackSpeedState } from '@/shared/hooks/websocket/usePlaybackSpeed';
import { Tracking } from '@/shared/services/tracking';
import useClipStateContext from '@/shared/hooks/useClipStateContext';

import useCognito from '../../authentication/hooks/useCognito';
import useEnqueueClipForExportMutation from '../hooks/useEnqueueClipForExportMutation';
import { ClipModel, ClipStatus, ClipType } from '../types';

type Props = {
  clip: ClipModel;
  visible: boolean;
  downloadCliphandler: (clipId: number) => void;
  exportClickHandler: () => void;
};

export default function ClipRecordContextMenu({
  clip,
  visible,
  exportClickHandler,
  downloadCliphandler,
}: Props) {
  // react hooks
  const cognito = useCognito();
  const { clipDownload } = useFlags();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const exportClipMutation = useEnqueueClipForExportMutation();
  const [, setPlaybackSpeed] = usePlaybackSpeedState();

  const open = Boolean(anchorEl);
  const theme = useTheme();

  const errorColor = theme.palette.error.main;

  const isOwnClip = cognito.userAttributes?.email === clip.clouduser?.email;
  const { handleConvertToClip, handleDelete, handleStartEditing, handleView } =
    useClipStateContext();

  // handlers
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = () => {
    setPlaybackSpeed(0);

    setTimeout(() => {
      exportClipMutation.mutate(clip.id);
      if (exportClickHandler) {
        exportClickHandler();
      }
    }, 250);
  };

  const exportOrDownload = () => {
    if (clip.status === ClipStatus.READY) {
      downloadCliphandler(clip.id);
      Tracking.getInstance().track('Download', {
        category: 'Bookmarks/Clips',
        type: 'clip',
      });
    } else {
      handleExport();
      Tracking.getInstance().track('Export', {
        category: 'Bookmarks/Clips',
        type: 'clip',
      });
    }
  };

  const id = `clip-record-context-menu-${clip.id}`;

  return (
    <>
      <Tooltip title="Actions" arrow>
        <IconButton
          size="small"
          id={id}
          aria-controls={open ? id : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ ml: 1, opacity: visible ? '1' : '0' }}
        >
          <MoreHorIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id={id}
        aria-labelledby={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleStartEditing(clip);
          }}
          disabled={!isOwnClip}
        >
          Edit
        </MenuItem>
        {false && clip.type === ClipType.Bookmark && (
          <MenuItem
            onClick={() => {
              handleClose();
              handleConvertToClip(clip);
            }}
            disabled={!isOwnClip}
          >
            Convert to Clip
          </MenuItem>
        )}
        {clip.type === ClipType.Clip && clipDownload && (
          <MenuItem
            onClick={() => {
              handleClose();
              exportOrDownload();
            }}
          >
            Download
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            handleView(clip);
          }}
        >
          View
          {clip.type === ClipType.Bookmark ? ' in Timeline' : ''}
        </MenuItem>

        <Divider></Divider>
        <MenuItem
          sx={{ color: errorColor }}
          onClick={() => {
            handleClose();
            handleDelete(clip);
          }}
          disabled={!isOwnClip}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}
