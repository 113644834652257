import { useMemo, useState, forwardRef } from 'react';

import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import {
  Box,
  Container,
  Typography,
  Divider,
  AppBar as MuiAppBar,
  ListItemText,
  Dialog,
  IconButton,
  Toolbar,
  Slide,
  Button,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import AppPage from '../../../shared/components/PageComponents/AppPage';
import GamesForm, {
  DEFAULT_VALUES,
  FormValues as GamesFormValues,
} from '@/features/games/components/GamesForm';
import { PAGE_SIZE, useGamesQuery } from '@/features/games/hooks/useGameQuery';
import ZenDataGrid from '@/shared/components/ZenDataGrid';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { formatDate } from 'date-fns';
import GameEditionForm from '@/features/games/components/GameEditionForm';

import { TransitionProps } from '@mui/material/transitions';

import CloseIcon from '@mui/icons-material/Close';
import { GameModel } from '@/features/games/types';
import useUpdateGameDetails from '@/features/games/hooks/updateGameDetailsMutation';
import { useNavigate } from 'react-router';
import GameMatchup from './GameMatchup';

function removeNullOrUndefined(
  obj: Record<string, GamesFormValues>,
): Record<string, GamesFormValues> {
  return Object.entries(obj)
    .filter(([key, value]) => value !== null && value !== undefined)
    .reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      {} as Record<string, GamesFormValues>,
    );
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GamesPage = () => {
  const [formValues, setFormValues] = useState<GamesFormValues>(DEFAULT_VALUES);

  const gamesQuery = useGamesQuery(formValues);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const total = useMemo(() => gamesQuery.data?.pages[0].total, [gamesQuery.data]);
  const allGames = useMemo(
    () => gamesQuery.data?.pages.flatMap((p) => p.results),
    [gamesQuery.data],
  );

  const isLoading = useMemo(
    () => gamesQuery.isLoading || gamesQuery.isFetchingNextPage,
    [gamesQuery.isLoading, gamesQuery.isFetchingNextPage],
  );

  const navigate = useNavigate();

  const title = 'Game Management';

  useDocumentTitle(title);

  const handleFilterAndSortChange = (values: GamesFormValues) => {
    setFormValues(removeNullOrUndefined(values));
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Title',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GameMatchup game={params.row}/>,
    },
    {
      field: 'startTimestamp',
      headerName: 'Start Time',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>{`${formatDate(new Date(params.row.startTimestamp), 'PPpp')}`}</>
      ),
    },
    {
      field: 'streamId',
      headerName: 'Stream ID (Tiledmedia Encoder ID)',
      flex: 1,
    },
    {
      field: 'venue',
      headerName: 'Location',
      width: 200,
      renderCell: (params: GridRenderCellParams) => <>{`${params.row.venue.name}`}</>,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
    },
  ];

  const [open, setOpen] = useState(false);
  const [editingGame, setEditingGame] = useState<GameModel | null>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const updateGameDetails = useUpdateGameDetails();

  const [updatedDetails, setUpdatedDetails] = useState<GameModel | null>(null);

  async function handleSaveGameDetails() {
    updateGameDetails.mutate(updatedDetails);
    gamesQuery.refetch();
    handleClose();
  }

  function showEditDialog(game: GameModel) {
    setEditingGame(game);
    setOpen(true);
  }

  return (
    <AppPage title={title} requiredPermissions={['menu:manage:games']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">Manage Games</Typography>
        <Typography variant="body1">Enable / Disable Games, edit games etc.</Typography>
        <Divider sx={{ marginTop: 3 }} />

        <GamesForm onChange={handleFilterAndSortChange} available={false} />

        {total !== undefined ? (
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            {total === 0 ? 'No games found.' : total > 1 ? `${total} Games` : `${total} Game`}
          </Typography>
        ) : null}

        {gamesQuery.isError ? (
          <Typography color="error" sx={{ textAlign: 'center' }}>
            An error occurred while fetching games.
          </Typography>
        ) : null}

        <Box sx={{ height: 600, marginTop: 4 }}>
          <ZenDataGrid
            rows={allGames || []}
            columns={columns}
            pagination
            sortingMode="server"
            filterMode="server"
            paginationMode="server"
            loading={isLoading}
            rowCount={total || 0}
            onRowClick={(params) => showEditDialog(params.row as GameModel)}
            onPaginationModelChange={(newPaginationModel) => {
              // fetch data from server
              console.log(newPaginationModel);
            }}
            onSortModelChange={(newSortModel) => {
              // fetch data from server
              console.log(newSortModel);
            }}
            onFilterModelChange={(newFilterModel) => {
              // fetch data from server
              console.log(newFilterModel);
            }}
          />
        </Box>
      </Container>
      <Dialog open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
        <MuiAppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Game
            </Typography>
            <Button
              variant="contained"
              autoFocus
              onClick={() => {
                handleSaveGameDetails();
                navigate(`/games/${editingGame?.uniqueId}`);
              }}
              sx={{ marginRight: 2 }}
            >
              View Game
            </Button>
            <Button variant="contained" autoFocus onClick={handleSaveGameDetails}>
              Save
            </Button>
          </Toolbar>
        </MuiAppBar>
        <GameEditionForm
          gameDetails={editingGame}
          handleGameDataChange={(gameDetails) => setUpdatedDetails(gameDetails)}
        />
      </Dialog>
    </AppPage>
  );
};

export default GamesPage;
