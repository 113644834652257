import { format, parseISO } from 'date-fns';
import { FileDownload } from '@mui/icons-material';
import { Box, Chip, Stack, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import useEnqueueClipForExportMutation from '../hooks/useEnqueueClipForExportMutation';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import ZenDataGrid from '@/shared/components/ZenDataGrid';

type Team = {
  abbreviation: string;
  logoPath: string;
};

type Row = {
  note: string;
  mediatype: string;
  gameAwayTeam: Team;
  gameHomeTeam: Team;
  gameDate: string;
  createdAt: string;
  updatedAt: string;
  status: string;
};

type Props = {
  rows: Row[];
  loading: boolean;
  pageSize: number;
  setPageSize: (newPageSize: number) => void;
  page: number;
  setPage: (newPage: number) => void;
  rowCount: number;
};

const getChipStatusColor = (status: string) => {
  switch (status.toUpperCase()) {
    case 'READY':
      return 'success';
    case 'ERROR':
      return 'error';
    case 'CANCELED':
    case 'PENDING':
    case 'UNPROCESSED':
      return 'info';
    default:
      return 'warning';
  }
};

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>
);

const ClipsTable = ({ rows, loading, pageSize, setPageSize, page, setPage, rowCount }: Props) => {
  const exportClipMutation = useEnqueueClipForExportMutation();

  const columns: GridColDef[] = [
    {
      field: 'note',
      headerName: 'Name',
      width: 270,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box sx={{ textTransform: 'uppercase' }}>{params.colDef.headerName}</Box>
      ),
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={{ textTransform: 'capitalize', overflowWrap: 'break-word' }}>
          {params.row.note}
        </Box>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box sx={{ textTransform: 'uppercase' }}>{params.colDef.headerName}</Box>
      ),
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={{ textTransform: 'capitalize' }}>{params.row.mediatype}</Box>
      ),
    },
    {
      field: 'gameName',
      headerName: 'Game',
      width: 200,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Stack spacing={1} sx={{height: '100%'}}>
          <Stack direction="row" spacing={2} alignItems="center" sx={{height: '100%'}}>
            <img
              src={getTeamLogoPath(params.row.gameAwayTeam)}
              width="30"
              loading="lazy"
              style={{ marginRight: 10 }}
              alt={params.row.gameAwayTeam.abbreviation}
            />
            <Typography>{params.row.gameAwayTeam.abbreviation}</Typography>
            <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
              @
            </Typography>
            <Typography>{params.row.gameHomeTeam.abbreviation}</Typography>
            <img
              src={getTeamLogoPath(params.row.gameHomeTeam)}
              width="30"
              loading="lazy"
              style={{ marginRight: 10 }}
              alt={params.row.gameHomeTeam.abbreviation}
            />
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'gameDate',
      headerName: 'Game Date',
      type: 'date',
      width: 200,
      valueFormatter: ({ value }) => (value ? format(parseISO(value), 'MMM dd, yyyy') : ''),
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      type: 'date',
      width: 200,
      valueFormatter: ({ value }) => (value ? format(parseISO(value), 'MMM dd, yyyy') : ''),
    },
    {
      field: 'updatedAt',
      headerName: 'Date Modified',
      type: 'date',
      width: 200,
      valueFormatter: ({ value }) => (value ? format(parseISO(value), 'MMM dd, yyyy') : ''),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip
          label={params.row.status.toUpperCase()}
          size="small"
          color={getChipStatusColor(params.row.status)}
          sx={{ borderRadius: 1, fontSize: 10 }}
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          disabled={params.row.mediatype === 'bookmark'}
          icon={<FileDownload />}
          label="Export"
          onClick={() => {
            exportClipMutation.mutate(params.id);
          }}
        />,
      ],
    },
  ];

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <ZenDataGrid
        rows={rows}
        loading={loading}
        components={{
          Toolbar: CustomToolbar,
        }}
        pageSize={pageSize}
        rowHeight={80}
        autoHeight
        columns={columns}
        rowCount={rowCount}
        rowsPerPageOptions={[5]}
        pagination
        page={page}
        paginationMode="server"
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
    </Box>
  );
};

export default ClipsTable;
