import DownloadIcon from '@mui/icons-material/Download';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Tracking } from '../services/tracking';
import useClipDownloadMutation from '@/features/clips/hooks/useClipDownloadMutation';
import useCurrentGameQuery from '@/features/games/hooks/useCurrentGameQuery';

export interface CamerasDialogProps {
  open: boolean;
  clipId: number;
  onClose?: () => void;
}

function DownloadClipDialog(props: CamerasDialogProps) {
  const { onClose, open, clipId } = props;

  const { data } = useCurrentGameQuery();
  const downloadClipMutation = useClipDownloadMutation();

  const cameras = data?.cameras;

  const handleClose = () => {
    onClose?.();
  };

  const handleDownload = (cameraId: number) => {
    downloadClipMutation.mutate({
      clipId,
      cameraId,
    });
  };

  const handleMetadataDownload = () => {
    downloadClipMutation.mutate({
      clipId,
      cameraId: 0
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        backdropFilter: 'blur(5px)',
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(4px)', // Default blur effect
          }
        }
      }}
    >
      <DialogTitle>Download Clip</DialogTitle>
      {cameras && (
        <List sx={{ pt: 0 }}>
          {Object.values(cameras)
            .filter((c) => c.id)
            .map((camera) => (
              <ListItem key={camera.id}>
                <ListItemText
                  primary={camera.name}
                  secondary={`camera ${camera.id}`}
                  sx={{ mr: -1, minWidth: 300 }}
                />
                <ListItemButton
                  onClick={() => {
                    handleDownload(camera.id);
                    Tracking.getInstance().track('Download', {
                      category: 'Bookmarks/Clips',
                      type: 'clip',
                    });
                  }}
                  key={camera.id}
                >
                  <DownloadIcon />
                </ListItemButton>
              </ListItem>
            ))}
          <ListItem key="-1">
            <ListItemText
              primary="Metadata file"
              secondary="metadata.json"
              sx={{ mr: -1, minWidth: 300 }}
            />
            <ListItemButton
              onClick={() => {
                handleMetadataDownload();
                Tracking.getInstance().track('Download Metadata', {
                  category: 'Bookmarks/Clips',
                  type: 'clip',
                });
              }}
              key="-1"
            >
              <DownloadIcon />
            </ListItemButton>
          </ListItem>
        </List>
      )}

      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DownloadClipDialog;
