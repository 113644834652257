import { useMemo } from 'react';
import { Button, Box, Typography, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ZenDataGrid from '@/shared/components/ZenDataGrid';

const SessionsGrid = ({ sessions, onKillSession }) => {
  // Transform sessions object into array for DataGrid
  const rows = useMemo(() => {
    return Object.entries(sessions).map(([email, session]) => ({
      id: session.sessionId,
      email: email,
      handler: session.handler,
      status: session.status,
      reserved: session.computeInstance?.reserved ? 'Yes' : 'No',
      timezone: session.computeInstance?.clouduser?.tz || 'Unknown',
      formattedLastActivity: session.formattedLastActivity || 'Unknown',
      lastActivity: session.lastActivity, // Used for sorting
    }));
  }, [sessions]);

  const columns = [
    {
      field: 'handler',
      headerName: 'Handler',
      width: 130,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 350,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={
            params.value === 'ready' ? 'success' : params.value === 'deploying' ? 'info' : 'warning'
          }
        />
      ),
    },
    {
      field: 'reserved',
      headerName: 'Reserved',
      width: 130,
    },
    {
      field: 'timezone',
      headerName: 'Timezone',
      width: 200,
    },
    {
      field: 'formattedLastActivity',
      headerName: 'Last Activity',
      width: 150,
      sortComparator: (v1, v2, param1, param2) => {
        if (!param1.row || !param2.row) {
          return 0; // or handle missing rows differently
        }
        return (param1.row.lastActivity || 0) - (param2.row.lastActivity || 0);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => onKillSession(params.row.id)}
        >
          Kill
        </Button>
      ),
    },
  ];

  return (
    <Box className="h-[600px] w-full">
      <Typography variant="h6" className="mb-4">
        Active Sessions
      </Typography>
      <ZenDataGrid
        rows={rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'formattedLastActivity', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        className="bg-white"
      />
    </Box>
  );
};

export default SessionsGrid;
