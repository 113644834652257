import { isNullOrUndefinedOrEmpty } from '@/shared/services/isNullOrUndefinedOrEmpty';
import { UsersByGroups } from '@/types/user';
import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';

export function getQueryKey(orgId: number) {
  return ['AllUsers', orgId];
}

export const useAllUsersQuery = (orgId?: number, opt?: any) =>
  useQuery({
    queryKey: getQueryKey(orgId as number),
    queryFn: async () => {
      const { data } = await get<UsersByGroups[]>(`users/list`);
      return data;
    },

    enabled: !isNullOrUndefinedOrEmpty(orgId, 'number'),
    gcTime: 0,
    ...opt
  });

export default useAllUsersQuery;
