import { ReactNode, useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { useQueryClient } from '@tanstack/react-query';
import AppBar, { AppBarSpacer } from './AppBar';
import useCamerasQuery, {
  getQueryKey as getCamerasQueryKey,
} from '@/features/camera/hooks/useCamerasQuery';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import NavigationMenu from './NavigationMenu';
import { DRAWER_WIDTH } from '@/features/games/constants';
import AccessDenied from '../AccessDenied';
import { LoadingSpinner } from '../LoadingSpinner';
import { UserStateContext } from '../UserStateProvider';

type PageMenuRenderFunction = (open: boolean, setOpen: (open: boolean) => unknown) => ReactNode;

type Props = {
  children: ReactNode;
  title: string;
  renderPageMenu?: PageMenuRenderFunction;
  renderSummaryData?: () => ReactNode;
  pageMenuWidth?: number;
  requiredPermissions?: string[];
};

const AppPage = ({
  children,
  title,
  renderPageMenu,
  renderSummaryData,
  pageMenuWidth = DRAWER_WIDTH,
  requiredPermissions,
}: Props) => {
  const gameId = useCurrentGameId();
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [pageMenuOpen, setPageMenuOpen] = useState(renderPageMenu !== undefined);
  const cameraSettingsStateQuery = useCamerasQuery();
  const queryClient = useQueryClient();

  const { hasRole } = useContext(UserStateContext);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: getCamerasQueryKey(gameId) });
    cameraSettingsStateQuery.refetch();
  }, []);

  if (!hasRole) {
    return <LoadingSpinner text={'loading...'} />;
  }

  if (requiredPermissions && !hasRole(requiredPermissions)) {
    return <AccessDenied />;
  }

  return (
    <>
      <NavigationMenu open={navigationOpen} setOpen={setNavigationOpen} width={300} />

      <AppBar
        title={title}
        navigationOpen={navigationOpen}
        setNavigationOpen={setNavigationOpen}
        setPageMenuOpen={setPageMenuOpen}
        pageMenuOpen={pageMenuOpen}
        pageMenuWidth={pageMenuWidth}
        hasPageMenu={renderPageMenu !== undefined}
        renderSummaryData={renderSummaryData}
      />

      <AppBarSpacer />

      <Box
        component="main"
        sx={(theme) => ({
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(pageMenuOpen && {
            width: `calc(100% - ${pageMenuWidth}px)`,
            marginRight: `${pageMenuWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        })}
      >
      
        {children}
      </Box>

      {renderPageMenu && renderPageMenu(pageMenuOpen, setPageMenuOpen)}
    </>
  );
};

export default AppPage;
