import { useInfiniteQuery } from '@tanstack/react-query';

import { FormValues as GamesFormValues } from '../components/GamesForm';
import { GetGamesResult } from '../types/GetGamesResult';
import { fetchGames } from '../services/fetchGames';
import { isNullOrUndefinedOrEmpty } from '@/shared/services/isNullOrUndefinedOrEmpty';

export const PAGE_SIZE = 10;

export function getQueryKey(formValues: GamesFormValues) {
  return ['Games', { formValues }];
}

const getNextPageParam = (lastPage: GetGamesResult) =>
  lastPage.offset + lastPage.results.length < lastPage.total ? lastPage.offset + PAGE_SIZE : undefined;

export const useGamesQuery = (formValues: GamesFormValues) =>
  useInfiniteQuery({
    queryKey: getQueryKey(formValues),
    queryFn: async ({ pageParam: offset = 0 }) => {
      
      const { data } = await fetchGames({ offset, limit: PAGE_SIZE, ...formValues });
      return data;
    },
    refetchOnWindowFocus: true,
    getNextPageParam,
    initialPageParam: 0,
    enabled: !isNullOrUndefinedOrEmpty(Number(formValues.leagueId),'number'), // Disable the query if leagueId is undefined
  });
