import { addSeconds, differenceInMilliseconds, subMilliseconds } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { KeyboardShortcut, KeyboardShortcuts } from '@/shared/constants';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import useKeyboardShortcutsDialog from '@/shared/hooks/useKeyboardShortcutsDialog';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import FastRewindRoundedIcon from '@mui/icons-material/FastRewindRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Tooltip as BaseTooltip, Divider, Stack, TooltipProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useJumpFrame from '@/shared/hooks/websocket/useJumpFrame';
import { usePlaybackSpeedState } from '@/shared/hooks/websocket/usePlaybackSpeed';
import { useTimestampState } from '@/shared/hooks/websocket/useTimestamp';

import { displayInterval } from '@/shared/services';
import displayWallClock from '@/shared/services/displayWallClock';
import { Tracking } from '@/shared/services/tracking';

import useCurrentGameQuery from '../../games/hooks/useCurrentGameQuery';
import usePlayerHotkeys from '../hooks/usePlayerHotkeys';
import OneFrameBackward from './OneFrameBackwardIcon';
import OneFrameForward from './OneFrameForwardIcon';
import OneSecondBackward from './OneSecondBackwardIcon';
import OneSecondForward from './OneSecondForwardIcon';
import PlaybackPositionSlider from './PlaybackPositionSlider';
import PlaybackSpeedSelectionButton from './PlaybackSpeedSelectionButton';
import PlayerCameraFeedSelectionButton from './PlayerCameraFeedSelectionButton';

import { ViewType } from '@/features/camera/types/VideoSource';
import AudioControls from './AudioControls';

export type GamePlayBackPositions = {
  head: number;
  live: number;
  start: number;
};

type Props = {
  exitFullscreen: () => Promise<void>;
  isFullscreen: boolean;
  requestFullscreen: () => Promise<void>;
  onStatsToggle?: () => unknown;
  onMuteToggle: () => unknown;
  onVolumeChange: (volume: number) => unknown;
};

type PlayerControlProps = {
  children: React.ReactNode;
  color?: string;
  disabled?: boolean;
  onClick: () => unknown;
  title: string;
};

const Tooltip = (props: TooltipProps) => (
  <BaseTooltip arrow placement="top" PopperProps={{ disablePortal: true }} {...props} />
);

const PRIMARY_COLOR = '#ffffff';
const SECONDARY_COLOR = 'b8b8b8';

export const PlayerControl = ({
  children,
  color = PRIMARY_COLOR,
  disabled = false,
  onClick,
  title,
}: PlayerControlProps) => (
  <Box>
    <Tooltip title={title} arrow>
      <IconButton size="small" onClick={onClick} sx={{ color }} disabled={disabled}>
        {children}
      </IconButton>
    </Tooltip>
  </Box>
);

const displayCommand = (shortcut: KeyboardShortcut) => `${shortcut.command} (${shortcut.hotkey})`;

const PlayerControls = ({
  requestFullscreen,
  exitFullscreen,
  isFullscreen,
  onMuteToggle,
  onVolumeChange,
  onStatsToggle = () => {},
}: Props) => {
  const { selectMosaic, viewType, gameStateManager } = useServerStateContext();

  const [playbackHead, setPlaybackHead] = useState<number>(-1);
  const [playbackLive, setPlaybackLive] = useState<number>(-1);
  const [playbackStart, setPlaybackStart] = useState<number>(-1);

  const [showingBookmarks, setShowingBookmarks] = useState(true);
  const [, setTimestamp] = useTimestampState();

  const gameQuery = useCurrentGameQuery();

  const [, setPlaybackSpeed] = usePlaybackSpeedState();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const { jumpFrame } = useJumpFrame();
  const { liveTimestampOffset, ffAndRw, enableQuadView } = useFlags();

  useEffect(() => {
    if (!gameStateManager) return;

    
    const updatePlaybackProperties = (key: keyof GamePlayBackPositions, value: any) => {
      switch (key) {
        case 'head':
          setPlaybackHead(value / 1_000_000);
          break;
        case 'live':
          setPlaybackLive(value / 1_000_000);
          break;
        default:
          break;
      }
    };

    const handleHeadChange = (head: number) => {
      updatePlaybackProperties('head', head);
    };

    const handleSpeedChange = (speed: number) => {
      setIsPlaying(speed === 1);
    };

    const handleLiveChange = (live: number) => {
      updatePlaybackProperties('live', live);
    };

    gameStateManager.on('headChange', handleHeadChange);
    gameStateManager.on('speedChange', handleSpeedChange);
    gameStateManager.on('liveChange', handleLiveChange);

    return () => {
      if (gameStateManager.off) {
        gameStateManager.off('headChange', handleHeadChange);
        gameStateManager.off('speedChange', handleSpeedChange);
        gameStateManager.off('liveChange', handleLiveChange);
      }
    };
  }, [gameStateManager]);

  const durationLabel = useCallback(
    (seconds: number) => {
      try {
        if (playbackStart !== -1)
          return displayInterval({
            start: playbackStart,
            end: addSeconds(playbackStart, seconds),
          });
        return '-';
      } catch (e) {
        console.warn(
          `Warning: could not display duration label for ${seconds} seconds and ${playbackStart}`,
        );
        console.error(e);
        return '';
      }
    },
    [playbackStart],
  );

  const playbackPositionSeconds = useMemo(
    () => Math.round(differenceInMilliseconds(playbackHead, playbackStart) / 1000),
    [playbackHead, playbackStart],
  );

  const endPositionSeconds = useMemo(
    () => Math.round(differenceInMilliseconds(playbackLive, playbackStart) / 1000),
    [playbackLive, playbackStart],
  );

  const contentDuration = useMemo(() => durationLabel(endPositionSeconds), [endPositionSeconds]);

  const {
    state: { isEditing },
  } = useClipStateContext();

  const handleLiveButtonClick = useCallback(() => {
    if (!setTimestamp) return;
    const sent = subMilliseconds(playbackLive, liveTimestampOffset);
    setTimestamp(sent);
  }, [setTimestamp, endPositionSeconds, playbackHead, liveTimestampOffset]);

  const handleJump = useCallback(
    (jumpSeconds: number) => {
      if (!setTimestamp) return;
      const newPosition = addSeconds(playbackHead, jumpSeconds);
      setTimestamp(newPosition);
    },
    [setTimestamp, playbackHead],
  );

  const handlePlayPause = useCallback(
    (e: Event) => {
      if (!setPlaybackSpeed) return;
      setPlaybackSpeed(isPlaying ? 0 : 1);
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        type: !isPlaying ? 'Pause' : 'Play',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
    },
    [isPlaying, setPlaybackSpeed],
  );

  const handleJumpForwardOneSecond = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        unit: 'Second',
        type: 'Jump',
        direction: 'Forward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      handleJump(1);
    },
    [handleJump],
  );
  const handleJumpBackwardOneSecond = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        unit: 'Second',
        type: 'Jump',
        direction: 'Backward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      handleJump(-1);
    },
    [handleJump],
  );
  const handleJumpForwardOneFrame = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        unit: 'Frame',
        type: 'Jump',
        direction: 'Forward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      jumpFrame(1);
    },
    [jumpFrame],
  );
  const handleJumpBackwardOneFrame = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        type: 'Jump',
        unit: 'Frame',
        direction: 'backward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      jumpFrame(-1);
    },
    [jumpFrame],
  );

  const keyboardShortcutsModal = useKeyboardShortcutsDialog();

  usePlayerHotkeys(
    handlePlayPause,
    handleJumpForwardOneSecond,
    handleJumpBackwardOneSecond,
    handleJumpForwardOneFrame,
    handleJumpBackwardOneFrame,
  );

  const audioEnabled = useMemo(() => {
    return (gameQuery.data as any)?.enableAudio;
  }, [gameQuery?.data]);

  const inLiveMode = useMemo(() => {
    const status = (gameQuery.data as any)?.status;
    return status === 'live';
  }, [gameQuery?.data]);

  useEffect(() => {
    if (!gameQuery?.data || !gameQuery.data?.startTimestamp) return;
    setPlaybackStart(gameQuery.data.startTimestamp.getTime());
  }, [gameQuery?.data]);

  const isQuad = useMemo(() => viewType === ViewType.MOSAIC, [viewType]);

  const isLiveEdge = useMemo(
    () => playbackPositionSeconds / endPositionSeconds > 0.96,
    [playbackPositionSeconds, endPositionSeconds],
  );

  return (
    <>
      <PlaybackPositionSlider
        showingBookmarks={showingBookmarks}
        gamePlaybackPositions={{ head: playbackHead, live: playbackLive, start: playbackStart }}
      />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ overflowX: 'auto', p: 1 }}
      >
        <Stack direction="row" alignItems="center" spacing={1} px={2}>
          <PlayerControl
            title={displayCommand(KeyboardShortcuts.ShuttleBackward)}
            onClick={handleJumpBackwardOneSecond}
          >
            <OneSecondBackward />
          </PlayerControl>

          {!!ffAndRw && (
            <PlayerControl
              title="Rewind"
              onClick={() => {
                setPlaybackSpeed(-6);
                Tracking.getInstance().track('Playback', {
                  category: 'Controls',
                  type: 'Rewind',
                });
              }}
            >
              <FastRewindRoundedIcon />
            </PlayerControl>
          )}

          <PlayerControl
            title={displayCommand(KeyboardShortcuts.PlayPause)}
            onClick={handlePlayPause}
          >
            {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
          </PlayerControl>

          {!!ffAndRw && (
            <PlayerControl
              title="Fast Forward"
              onClick={() => {
                Tracking.getInstance().track('Playback', {
                  category: 'Controls',
                  type: 'Fast Forward',
                });
                setPlaybackSpeed(6);
              }}
            >
              <FastForwardRoundedIcon />
            </PlayerControl>
          )}

          <PlayerControl
            title={displayCommand(KeyboardShortcuts.ShuttleForward)}
            onClick={handleJumpForwardOneSecond}
          >
            <OneSecondForward />
          </PlayerControl>

          <Stack direction="row" spacing={2} sx={{ pl: 4 }}>
            <Typography style={{ fontVariantNumeric: 'tabular-nums' }} noWrap>
              {displayWallClock(new Date(playbackHead))}
            </Typography>
            <Typography>|</Typography>
            <Typography noWrap>{contentDuration}</Typography>
          </Stack>
        </Stack>

        <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}>
          <Stack direction="row" alignItems="center">
            {audioEnabled && (
              <AudioControls onMuteToggle={onMuteToggle} onVolumeChange={onVolumeChange} />
            )}
            {inLiveMode && (
              <Stack
                direction="row"
                spacing={1}
                style={{ marginRight: 20, cursor: 'pointer' }}
                onClick={handleLiveButtonClick}
              >
                <CircleRoundedIcon color={isLiveEdge ? 'error' : 'disabled'} sx={{ width: 16 }} />
                <Typography>Live</Typography>
              </Stack>
            )}
            <PlayerControl
              title={`${showingBookmarks ? 'Hide' : 'Show'} Bookmarks`}
              onClick={() => setShowingBookmarks(!showingBookmarks)}
              color={showingBookmarks ? PRIMARY_COLOR : SECONDARY_COLOR}
            >
              {showingBookmarks ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            </PlayerControl>

            {!!ffAndRw && (
              <Tooltip title="Playback Speed" arrow>
                <Box>
                  <PlaybackSpeedSelectionButton disabled={isEditing} />
                </Box>
              </Tooltip>
            )}

            <PlayerControl
              title="Keyboard Shortcuts"
              onClick={() => keyboardShortcutsModal.show()}
              color={keyboardShortcutsModal.showing ? PRIMARY_COLOR : SECONDARY_COLOR}
            >
              <KeyboardIcon />
            </PlayerControl>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            {enableQuadView && (
              <>
                <Typography variant="subtitle1" noWrap>
                  Select View
                </Typography>

                <PlayerControl
                  title="Quad Camera"
                  onClick={() => selectMosaic('Video Player Toolbar')}
                  color={isQuad ? PRIMARY_COLOR : SECONDARY_COLOR}
                >
                  <GridViewRoundedIcon />
                </PlayerControl>
              </>
            )}

            <Tooltip title="Single Camera" arrow>
              <Box>
                <PlayerCameraFeedSelectionButton />
              </Box>
            </Tooltip>

            {isFullscreen ? (
              <PlayerControl title="Exit Fullscreen" onClick={exitFullscreen} color={PRIMARY_COLOR}>
                <FullscreenExitRoundedIcon />
              </PlayerControl>
            ) : (
              <PlayerControl title="Fullscreen" onClick={requestFullscreen} color={SECONDARY_COLOR}>
                <FullscreenRoundedIcon />
              </PlayerControl>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default PlayerControls;
