import { CognitoUser } from 'amazon-cognito-identity-js';
import { SignOutParams, UpdateCognitoContext } from './types';


const signOut =
  (user: CognitoUser, updateCognitoContext: UpdateCognitoContext) =>
    ({ onSuccess, onFailure }: SignOutParams) => {
      if (!user) {
        console.warn('No user to sign out');
        return;
      }
      user.globalSignOut({
        onSuccess() {
          updateCognitoContext({
            userAttributes: undefined,
            user: undefined,
            userSession: undefined,
            authenticated: false,
            newPasswordRequired: false,
          });

          onSuccess();
        },

        onFailure(err) {
          onFailure(err.message);
        },
      });
    };

export default signOut;
