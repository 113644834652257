import * as React from 'react';
import { isUUID } from 'validator';

import {
  ClipStateProvider,
  KeyboardShortcutsDialogProvider,
  ServerStateProvider,
} from '@/shared/components';
import ConfirmationDialogProvider from '@/shared/components/ConfirmationDialogProvider';

import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import AppPage from '../../../shared/components/PageComponents/AppPage';
import useCurrentGameQuery from '../hooks/useCurrentGameQuery';
import useGameSwitchQuery from '../hooks/useGameSwitchQuery';
import ScoreboardData from './ScoreboardData';
import ViewingToolsMenu from './ViewingToolsMenu';

import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';

import GamePageContents from './GamePageContents';
import LoadingGame from './LoadingGame';
import { DRAWER_WIDTH } from '../constants';
import { useNavigate } from 'react-router';

const GamePage = () => {
  const gameId = useCurrentGameId();
  const gameQuery = useCurrentGameQuery();
  const { data: game } = gameQuery;
  const switchGameQuery = useGameSwitchQuery();
  const navigate = useNavigate();

  useDocumentTitle(`${game?.name} - Game`);

  React.useEffect(() => {
    if (gameId && isUUID(gameId)) {
      switchGameQuery.refetch();
    } else {
      navigate('/games');
    }
  }, [gameId]);

  const gameLoaded = React.useMemo(
    () => gameQuery.status === 'success' && switchGameQuery.status === 'success' && game,
    [gameQuery.status, switchGameQuery.status, game],
  );

  return (
    <ConfirmationDialogProvider>
      <KeyboardShortcutsDialogProvider>
        <LoadingGame />

        {gameLoaded && (
          <ServerStateProvider>
            <ClipStateProvider>
              <AppPage
                title={game?.name || 'Loading...'}
                renderPageMenu={(open, setOpen) => (
                  <ViewingToolsMenu open={open} setOpen={setOpen} width={DRAWER_WIDTH} />
                )}
                renderSummaryData={() => <ScoreboardData data={game} />}
              >
                <GamePageContents gameId={game?.id} />
              </AppPage>
            </ClipStateProvider>
          </ServerStateProvider>
        )}
      </KeyboardShortcutsDialogProvider>
    </ConfirmationDialogProvider>
  );
};

export default GamePage;
