import CXVideoHub from '@/assets/cx_video_hub.png';
import { Stack } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';

type Props = {
  leagueLogo: string;
  height: number;
  justifyContent?: string;
};

export default function AnimatedCosmLogo({ leagueLogo, height, justifyContent = 'center' }: Props) {
  const logoControls = useAnimation();
  const separatorControls = useAnimation();
  const cxVideoHubLogoControls = useAnimation();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    // Set mounted to true when the component is mounted
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return; // Only run the animation after the component is mounted

    const sequence = async () => {
      try {
        if (leagueLogo) {
          await logoControls.start({
            opacity: 1,
            x: 0,
            transition: { duration: 0.2 },
          });

          await cxVideoHubLogoControls.start({
            opacity: 1,
            transition: { duration: 1 },
          });

          await separatorControls.start({
            opacity: 1,
            transition: { duration: 0.2 },
          });
        } else {
          await logoControls.start({
            x: 0,
            transition: { duration: 1 },
          });

          await cxVideoHubLogoControls.start({
            opacity: 1,
            transition: { duration: 1 },
          });

          await separatorControls.start({
            opacity: 0,
            transition: { duration: 1 },
          });
        }
      } catch (e) {
        console.error('Animation error:', e);
      }
    };

    sequence();

    // Cleanup any ongoing animations on unmount
    return () => {
      logoControls.stop();
      separatorControls.stop();
      cxVideoHubLogoControls.stop();
    };
  }, [leagueLogo, mounted, logoControls, separatorControls, cxVideoHubLogoControls]);

  return (
    <Stack direction="row" justifyContent={justifyContent} alignItems="center" sx={{ margin: 2 }}>
      <motion.div initial={{ opacity: 0 }} animate={cxVideoHubLogoControls}>
        <img src={CXVideoHub} alt="CXVideo Hub" style={{ height: 10, marginRight: 10 }} />
      </motion.div>
      {leagueLogo && (
        <>
          <motion.div
            style={{
              height: height - 10,
              borderLeft: '1px solid lightgray',
            }}
            initial={{ opacity: 0 }}
            animate={separatorControls}
          />

          <motion.img
            src={leagueLogo}
            alt="League Logo"
            style={{
              height,
              paddingLeft: 10,
            }}
            initial={{ opacity: 0, x: -50 }}
            animate={logoControls}
          />
        </>
      )}
    </Stack>
  );
}