import React, { useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';

import {
  KeyboardShortcut,
  KeyboardShortcutGroup,
  KeyboardShortcutGroupOrderNumber,
  KeyboardShortcutGroups,
  KeyboardShortcutGroupTitles,
  KeyboardShortcutsList,
} from '../constants';
import useKeyboardShortcutsDialog from '../hooks/useKeyboardShortcutsDialog';
import KeyboardCmd from './KeyboardCmd';
import { MouseDiagram } from '@/features/games/components';
import useCamerasQuery from '@/features/camera/hooks/useCamerasQuery';

type Props = {
  shortcuts?: Record<KeyboardShortcutGroup, KeyboardShortcut[]>;
};

const KeyboardShortcutsDialog = ({ shortcuts = KeyboardShortcutsList }: Props) => {
  const { showing, hide } = useKeyboardShortcutsDialog();
  const cameraSettingsStateQuery = useCamerasQuery();

  useEffect(() => {
    if (cameraSettingsStateQuery.status === 'success') {
      const cameras = cameraSettingsStateQuery.data.data; // Assume this contains the camera list
      if(!cameras) return;
      cameras.forEach((camera: { idx: number; name: string }, index: number) => {
        if (index < 9) {
          // Map to hotkeys 1-9
          const hotkey = (index + 1).toString(); // '1', '2', ..., '9'
          // Dynamically add to KeyboardShortcuts
          shortcuts[`Cameras`] = shortcuts[`Cameras`] || [];
          if (!shortcuts[`Cameras`].some((s) => s.hotkey === hotkey)) {
            shortcuts[`Cameras`].push({
              command: camera.name,
              hotkey,
              keys: [hotkey],
              group: KeyboardShortcutGroups.Cameras, // Custom group for cameras
            });
          }
        }
      });
    }
  }, [cameraSettingsStateQuery.status, cameraSettingsStateQuery.data]);


  return (
    <Dialog open={showing} onClose={hide} maxWidth={false} disablePortal    slotProps={{
      backdrop: {
        sx: {
          backdropFilter: 'blur(4px)', // Default blur effect
        }
      }
    }}>
      <DialogTitle>Keyboard Shortcuts</DialogTitle>

      <DialogContent dividers>
        <Stack direction="row" spacing={8} sx={{ mt: 2 }}>
          {(Object.keys(shortcuts) as KeyboardShortcutGroup[])
            .sort(
              (a, b) => KeyboardShortcutGroupOrderNumber[a] - KeyboardShortcutGroupOrderNumber[b],
            )
            .map((group) => (
              <Box key={group}>
                <Typography variant="subtitle2">{KeyboardShortcutGroupTitles[group]}</Typography>
                {shortcuts[group].map((s) => (
                  <Stack key={s.command} direction="row" spacing={1} sx={{ mt: 1 }}>
                    <KeyboardCmd keys={s.keys} />
                    <Typography variant="overline" sx={{ ml: 1, whiteSpace: 'nowrap' }}>
                      {s.command}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            ))}

          <Box sx={{ width: '225px', m: 2, mt: 4 }}>
            <Typography variant="subtitle2">Mouse Controls</Typography>

            <MouseDiagram />
          </Box>
        </Stack>
      </DialogContent>

      <Divider sx={{ mx: 3 }} />

      <DialogActions>
        <Button onClick={hide} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KeyboardShortcutsDialog;
