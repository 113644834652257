import * as React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';

import { GamePositionsRepresentation } from '../../autoIso/components';
import useAutoIsoEntitiesQuery from '../../autoIso/hooks/useAutoIsoEntitiesQuery';
import { AutoIsoSelect } from '../../camera/components';
import useClipHotkeys from '../../clips/hooks/useClipHotkeys';
import ViewingToolsMenuExpandablePanel from './ViewingToolsMenuExpandablePanel';
import PtzControls from './PtzControls';

import 'simplebar-react/dist/simplebar.min.css';

import useCurrentGameQuery from '../hooks/useCurrentGameQuery';
import { PANEL_PADDING } from '../constants';
import TrackingAndBookmarksTabs from '@/shared/components/TrackingAndBookmarksTabs';
import BookmarksAndClipsPanel from '@/features/clips/components/ClipsAndBookmarksPanel';
import SectionTitle from '@/shared/components/SectionTitle';
import { useMemo } from 'react';
import ViewingMenu from './ViewingMenu';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => unknown;
  width: number;
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
  background: 'rgba(40, 40, 40)',
  width: '100%',
  zIndex: theme.zIndex.drawer,
}));

const ViewingToolsMenu = ({ open, setOpen, width }: Props) => {
  const { data: game } = useCurrentGameQuery();
  const [enableTracking, setEnableTracking] = React.useState(false);

  const {
    handleAddBookmark,
    handleAddClip,
    handleSetStartTime,
    handleSetEndTime,
    state: { isEditing },
  } = useClipStateContext();

  useClipHotkeys(handleAddBookmark, handleAddClip, handleSetStartTime, handleSetEndTime);

  const theme = useTheme();

  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  const autoIsoQuery = useAutoIsoEntitiesQuery();
  const [isFlipped, setIsFlipped] = React.useState(false);

  const gameTypeMetadataWidth = useMemo(
    () => width - Number(theme.spacing(6).slice(0, -2)),
    [width, theme.spacing],
  );
  const gameTypeMetadataHeight = useMemo(() => {
    return gameTypeMetadataQuery.data
      ? gameTypeMetadataWidth / gameTypeMetadataQuery.data.surfaceAspectRatio
      : 0;
  }, [gameTypeMetadataWidth, gameTypeMetadataQuery.data]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const enableTrackingMemo = useMemo(() => {
    return (
      (autoIsoQuery.status === 'success' &&
        autoIsoQuery.data?.list &&
        game &&
        game?.enableTracking &&
        autoIsoQuery.data.list.length > 0) ||
      false
    );
  }, [autoIsoQuery.status, autoIsoQuery.data?.list, game]);

  React.useEffect(() => setEnableTracking(enableTrackingMemo), [enableTrackingMemo]);

  const viewingToolsOrPtzControlsPanel =
    !isEditing &&
    autoIsoQuery.status === 'success' &&
    (enableTracking && autoIsoQuery.data.list.length > 0 ? (
      <>
        {enableTracking && (
          <ViewingToolsMenuExpandablePanel
            title={`Virtual ${gameTypeMetadataQuery?.data?.surfaceName || 'Surface'}`}
            onCollapse={(expanded) => console.log(expanded)}
          >
            <Box display="flex" justifyContent="center" m={1} mb={3}>
              <GamePositionsRepresentation
                elementSize={{ width: gameTypeMetadataWidth, height: gameTypeMetadataHeight }}
                isFlipped={isFlipped}
              />
            </Box>
          </ViewingToolsMenuExpandablePanel>
        )}

        <AutoIsoSelect  />
      </>
    ) : (
      !enableTracking && <PtzControls />
    ));

  return (
    <Drawer
      sx={{
        width: 420,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
          overflow: 'hidden',
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      {/* Provide spacing for header */}
      <DrawerHeader />

      <DrawerHeader
        sx={(sxTheme) => ({
          position: 'fixed',
          backgroundColor: sxTheme.palette.background.paper,
        })}
      >
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
        {!isEditing && (
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Viewing Tools
          </Typography>
        )}
        {isEditing && (
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Clipping Mode
          </Typography>
        )}
      </DrawerHeader>

      <TrackingAndBookmarksTabs
        trackingTab={viewingToolsOrPtzControlsPanel}
        bookmarksTab={<BookmarksAndClipsPanel />}
      />
    </Drawer>
  );
};

export default ViewingToolsMenu;
