import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { configureGlobalSSE, configureITMSSE, configureWebSocket, configureHttp } from '@/util';

// from local storage
const setHttpAuth = (userSession: CognitoUserSession, handler?: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const jwt = userSession.getAccessToken().getJwtToken();
  const identityToken = userSession.getIdToken().getJwtToken();
  //  const cognitoGroup: string = userSession.getAccessToken().payload['cognito:groups'][0];
  // setting up the handler for the ITM
  const path = '/'.concat(handler);
  configureHttp({
    jwt,
    path,
    identityToken,
  });
  configureWebSocket(jwt, handler);
  configureITMSSE({
    jwt,
    path,
    identityToken,
  });
  configureGlobalSSE({
    jwt,
    path,
    identityToken,
  });
};

export default setHttpAuth;
