import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { isUUID } from 'validator';
import { AxiosError } from 'axios';

import toGameModel from '../services/toGameModel';
import { GameModel } from '../types';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

export const queryKey = (gameId: string) => ['Games', gameId] as const;

const DEFAULT_GAME_MODEL: GameModel = {
  id: 0,
  name: '',
  status: '',
  leagueid: 0,
  isLive: false,
  teams: [],
  cameras: [],
  startTimestamp: null,
  endTimestamp: null
};

interface ApiResponse {
  data: GameModel;
}

const useCurrentGameQuery = () => {
  const gameId = useCurrentGameId();
  
  return useQuery({
    queryKey: queryKey(gameId),
    queryFn: async () => {
      if (!gameId || !isUUID(gameId)) {
        throw new Error('Invalid game ID');
      }
      
      const { data } = await get<ApiResponse>(`games/${gameId}`);
      return toGameModel(data);
    },
    retry: (failureCount, error) => {
      // Only retry for network errors, not for 404s or other client errors
      if (error instanceof AxiosError && error.response?.status >= 500) {
        return failureCount < 3;
      }
      return false;
    },
    staleTime: 30 * 1000, // Data considered fresh for 30 seconds
    gcTime: 5 * 60 * 1000,  // Data will be garbage collected after 5 minutes
    // Don't fetch if gameId is invalid
    enabled: Boolean(gameId) && isUUID(gameId),
    select: (data) => {
      // Transform or validate data if needed
      return data ?? DEFAULT_GAME_MODEL;
    }
  });
};

export default useCurrentGameQuery;