/* eslint no-case-declarations: "off" */
import { useEffect, useState } from 'react';

import { Search } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useFlags } from 'launchdarkly-react-client-sdk';
import useAllClipTagsQuery from '@/features/clipTags/hooks/useAllClipTagsQuery';
import { ClipModel, Tag } from '@/features/clips/types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const OPTIONS_TYPE = {
  NEW: 'new',
};

const filter = createFilterOptions();

type Props = {
  clip: ClipModel;
  setUpdateTags: (tags: Tag[]) => void;
};

export default function TagAddInline({ clip, setUpdateTags }: Props) {
  const { addPersonalTags } = useFlags();

  const clipTagsQuery = useAllClipTagsQuery();
  const [tagName, setTagName] = useState('');
  const [selectedAddTag, setSelectedAddTag] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const [tagOptions, setTagOptions] = useState<Tag[]>([]);
  const [tags, setTags] = useState<Tag[]>([]); // Default to empty array
  const [initialized, setInitialized] = useState<boolean>(false);
  
  // Set up tag options and initial tags when data and clip are available
  useEffect(() => {
    if (clip && clipTagsQuery.status === 'success' && Array.isArray(clipTagsQuery.data)) {
      setTagOptions(clipTagsQuery.data);
      setTags(clip.tags || []); // Default to empty array if undefined
      setInitialized(true);
    }
  }, [clip, clipTagsQuery.data, clipTagsQuery.status]);
  
  // Clear tag name when selectedAddTag is cleared
  useEffect(() => {
    if (!selectedAddTag) {
      setTagName('');
    }
  }, [selectedAddTag]);
  
  useEffect(() => {
    setUpdateTags(tags);
  }, [tags]);

  return (
    <div>
      <Stack spacing={2}>
          <Autocomplete
            disabled={!initialized}
            id="combo-box-demo"
            open={open}
            onOpen={openPopper}
            options={tagOptions}
            multiple
            openOnFocus
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            inputValue={tagName}
            value={tags || []}
            renderOption={(props, option, { selected }) => {
              const { key, ...restProps } = props;
            
              return (
                <li key={key} {...restProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name ? (
                    <Typography>
                      {option.name}{' '}
                      <Typography component="span" style={{ opacity: 0.6 }}>{option.value}</Typography>
                    </Typography>
                  ) : (
                    <Typography>{option}</Typography>
                  )}
                </li>
              );
            }}
            renderInput={(params) => (
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  {...params}
                  id="input-with-sx"
                  label="Add Tags"
                  placeholder="Add Tags ..."
                  variant="standard"
                  size="small"
                />
              </Box>
            )}
            onInputChange={(event, value) => {
              setTagName(value);
            }}
            onChange={(event, value, reason) => {
              const lastItem = value[value.length - 1];
              switch (reason) {
                case 'clear':
                  setTags([]);
                  break;
                case 'removeOption':
                  setTags(value);
                  break;
                case 'selectOption':
                  // @ts-ignore
                  if (lastItem.type === 'new') {
                    if (!addPersonalTags) {
                      break;
                    }
                    // if are adding an item that already exists.
                    const exists = tags.some((tagItem) => tagItem === lastItem.value);
                    if (exists) {
                      break;
                    }

                    setSelectedAddTag(lastItem);
                    setOpenDialog(true);
                    break;
                  }
                  // @ts-ignore
                  const exists = tags.some((tagItem) => tagItem.id === lastItem.id);

                  if (exists) {
                    // setSelectedTag(null);
                  } else {
                    // setSelectedTag(lastItem);
                    setTags(value);
                  }

                  break;
                case 'createOption':
                  if (!addPersonalTags) {
                    break;
                  }
                  setSelectedAddTag({ value: lastItem });
                  setOpenDialog(true);
                  break;
                default:
                  break;
              }
            }}
            onClose={closePopper}
            selectOnFocus
            handleHomeEndKeys
            filterOptions={(options:Tag[], params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== '') {
                filtered.push({
                  type: OPTIONS_TYPE.NEW,
                  value: params.inputValue,
                  name: 'Create new tag:',
                });
              }
              return filtered;
            }}
          />
        </Stack>
      {addPersonalTags && (
        <Dialog
          open={openDialog}
          onClose={() => {
            setSelectedAddTag(null);
            setOpenDialog(false);
          }}
        >
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent dividers>
            Do you want to add this option &quot;{selectedAddTag?.value ?? ''}&quot;?
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              autoFocus
              onClick={() => {
                setSelectedAddTag(null);
                setOpenDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                let maxId = 1;
                Object.values(tags).forEach((item) => {
                  if (item.id > maxId) {
                    maxId = item.id;
                  }
                });
                setTags([...tags, { id: maxId + 1, name: selectedAddTag.value }]);
                setOpenDialog(false);
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
