import { isNullOrUndefinedOrEmpty } from '@/shared/services/isNullOrUndefinedOrEmpty';
import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

export interface Season {
  id: number;
  name: string;
  seasonType: string;
  startDate: string;
  endDate: string;
  is_active: boolean;
}

export function getQueryKey(leagueId: number) {
  return ['Seasons', leagueId];
}

export const useListSeasonsQuery = (leagueId: number) =>
  useQuery({
    queryKey: getQueryKey(leagueId),
    queryFn: async () => {
      const response = await get<Season[]>('/league/seasons');
      return response.data.map((season) => ({
        id: season.id,
        name: season.name,
        seasonType: season.seasonType,
        startDate: new Date(season.startDate),
        endDate: new Date(season.endDate),
        is_active: season.is_active,
      })) as unknown as Season[];
    },
    gcTime: 0,
    enabled: !isNullOrUndefinedOrEmpty(leagueId, 'number'),
    placeholderData: [],
  });

export default useListSeasonsQuery;
