import { isNullOrUndefinedOrEmpty } from '@/shared/services/isNullOrUndefinedOrEmpty';
import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';



export function getQueryKey(leagueId?: number) {
  return ['GameTeams', leagueId];
}



const useGameTeamsQuery = (leagueId?: number, opt?: any) =>
  useQuery({
    queryKey: getQueryKey(leagueId),
    queryFn: async () => {
      const { data } = await get('teams');
      return data;
    },
    gcTime: 0,
    enabled: !isNullOrUndefinedOrEmpty(leagueId, 'number'),
    placeholderData: [],
    ...opt
  });

export default useGameTeamsQuery;
