interface WebSocketOptions {
    reconnectAttempts?: number;
    reconnectDelay?: number;
    autoReconnect?: boolean;
    messageTimeout?: number;
    watchdogInterval?: number;
    expectedMessageRate?: number;
    rateAlertThreshold?: number;
}

type WebSocketEventMap = {
    'connect': () => void;
    'disconnect': (event: CloseEvent) => void;
    'error': (error: Error) => void;
    'message': (data: any) => void;
    'rateAlert': (data: { currentRate: number; expectedRate: number }) => void;
}

export class WebSocketClient {
    private readonly url: string;
    private readonly options: Required<WebSocketOptions>;
    private readonly handlers: Map<keyof WebSocketEventMap, Array<(...args: any[]) => void>>;
    private ws: WebSocket | null = null;
    private reconnectCount = 0;
    private isReconnecting = false;
    private lastMessageTime = Date.now();
    private watchdogTimer: NodeJS.Timeout | null = null;
    private messageCount = 0;
    private lastStatsTime = Date.now();
    private lastRateAlertTime = 0;
    private readonly RATE_ALERT_COOLDOWN = 5000;

    constructor(url: string, options: WebSocketOptions = {}) {
        this.url = url;
        this.options = {
            reconnectAttempts: options.reconnectAttempts ?? 20,
            reconnectDelay: options.reconnectDelay ?? 1000,
            autoReconnect: options.autoReconnect ?? true,
            messageTimeout: options.messageTimeout ?? 500,
            watchdogInterval: options.watchdogInterval ?? 1000,
            expectedMessageRate: options.expectedMessageRate ?? 60,
            rateAlertThreshold: options.rateAlertThreshold ?? 0.8
        };
        this.handlers = new Map();
        this.startWatchdog();
    }

    private startWatchdog(): void {
        this.stopWatchdog();
        
        this.watchdogTimer = setInterval(() => {
            const now = Date.now();
            const messageAge = now - this.lastMessageTime;
            const timeSinceLastStats = now - this.lastStatsTime;
            const messageRate = (this.messageCount * 1000) / timeSinceLastStats;

            this.checkMessageRate(messageRate, now);

            if (this.ws?.readyState === WebSocket.OPEN) {
                console.log('[WebSocket] Stats:', {
                    messageRate: `${messageRate.toFixed(1)} msg/s`,
                    messageAge: `${messageAge}ms`,
                    state: this.getState(),
                    expected: `${this.options.expectedMessageRate} msg/s`
                });
            }

            this.messageCount = 0;
            this.lastStatsTime = now;

            if (this.ws?.readyState === WebSocket.OPEN && 
                messageAge > this.options.messageTimeout) {
                console.warn('[WebSocket] Connection timeout, reconnecting...');
                this.reconnect();
            }
        }, this.options.watchdogInterval);
    }

    private checkMessageRate(currentRate: number, now: number): void {
        const expectedRate = this.options.expectedMessageRate;
        const minAcceptableRate = expectedRate * this.options.rateAlertThreshold;
        
        if (currentRate < minAcceptableRate && 
            now - this.lastRateAlertTime > this.RATE_ALERT_COOLDOWN) {
            
            const alertData = {
                currentRate: Math.round(currentRate),
                expectedRate: expectedRate
            };

            console.warn('[WebSocket] Message rate alert:', {
                ...alertData,
                threshold: `${this.options.rateAlertThreshold * 100}%`,
                minAcceptable: Math.round(minAcceptableRate)
            });

            this.emit('rateAlert', alertData);
            this.lastRateAlertTime = now;
        }
    }

    private stopWatchdog(): void {
        if (this.watchdogTimer) {
            clearInterval(this.watchdogTimer);
            this.watchdogTimer = null;
        }
    }

    public connect(): boolean {
        if (this.ws?.readyState === WebSocket.OPEN) {
            return false;
        }

        try {
            this.ws = new WebSocket(this.url);
            this.setupEventHandlers();
            return true;
        } catch (error) {
            this.emitError(error);
            return false;
        }
    }

    private setupEventHandlers(): void {
        if (!this.ws) return;

        this.ws.onopen = () => {
            this.reconnectCount = 0;
            this.isReconnecting = false;
            this.lastMessageTime = Date.now();
            this.messageCount = 0;
            this.lastStatsTime = Date.now();
            this.lastRateAlertTime = 0;
            this.emit('connect');
            console.log('[WebSocket] Connected');
        };

        this.ws.onclose = (event) => {
            console.log('[WebSocket] Closed:', {
                code: event.code,
                reason: event.reason,
                wasClean: event.wasClean
            });
            this.emit('disconnect', event);
            this.handleReconnection();
        };

        this.ws.onerror = this.emitError.bind(this);

        this.ws.onmessage = (event: MessageEvent) => {
            try {
                const data = this.parseMessage(event.data);
                this.lastMessageTime = Date.now();
                this.messageCount++;
                this.emit('message', data);
            } catch (error) {
                this.emitError(error);
            }
        };
    }

    private parseMessage(data: any): any {
        if (typeof data === 'string') {
            try {
                return JSON.parse(data);
            } catch {
                return data;
            }
        }
        return data;
    }

    private handleReconnection(): void {
        if (!this.options.autoReconnect || 
            this.isReconnecting || 
            this.reconnectCount >= this.options.reconnectAttempts) {
            return;
        }

        this.isReconnecting = true;
        this.reconnectCount++;

        console.log('[WebSocket] Reconnecting:', {
            attempt: this.reconnectCount,
            maxAttempts: this.options.reconnectAttempts
        });

        setTimeout(() => {
            this.connect();
            this.isReconnecting = false;
        }, this.options.reconnectDelay);
    }

    private reconnect(): void {
        this.disconnect();
        this.handleReconnection();
    }

    public send(data: unknown): void {
        if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
            throw new Error('WebSocket is not connected');
        }

        const message = typeof data === 'string' ? data : JSON.stringify(data);
        this.ws.send(message);
    }

    public on<K extends keyof WebSocketEventMap>(event: K, callback: WebSocketEventMap[K]): void {
        if (!this.handlers.has(event)) {
            this.handlers.set(event, []);
        }
        this.handlers.get(event)?.push(callback);
    }

    public off<K extends keyof WebSocketEventMap>(event: K, callback?: WebSocketEventMap[K]): void {
        if (!callback) {
            this.handlers.delete(event);
            return;
        }

        const handlers = this.handlers.get(event);
        if (handlers) {
            const index = handlers.indexOf(callback);
            if (index !== -1) {
                handlers.splice(index, 1);
            }
        }
    }

    private emit<K extends keyof WebSocketEventMap>(
        event: K,
        ...args: Parameters<WebSocketEventMap[K]>
    ): void {
        this.handlers.get(event)?.forEach(callback => {
            try {
                callback(...args);
            } catch (error) {
                console.error(`[WebSocket] Event handler error (${String(event)}):`, error);
            }
        });
    }

    private emitError(error: unknown): void {
        this.emit('error', error instanceof Error ? error : new Error(String(error)));
    }

    public getState(): 'CONNECTING' | 'OPEN' | 'CLOSING' | 'CLOSED' {
        if (!this.ws) return 'CLOSED';

        return ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED'][this.ws.readyState];
    }

    public disconnect(): void {
        this.stopWatchdog();
        
        if (this.ws) {
            this.ws.onopen = null;
            this.ws.onclose = null;
            this.ws.onerror = null;
            this.ws.onmessage = null;
            
            if (this.ws.readyState === WebSocket.OPEN) {
                this.ws.close();
            }
            
            this.ws = null;
        }

        this.isReconnecting = false;
        this.reconnectCount = 0;
        this.messageCount = 0;
    }
}