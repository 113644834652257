import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { JsonEditor, githubDarkTheme } from 'json-edit-react';
import { DateTimePicker } from '@mui/x-date-pickers';
import GameMatchup from '@/features/settings/components/GameMatchup';

interface GameDetails {
  status: 'recorded' | 'live' | 'scheduled';
  startTimestamp: string;
  endTimestamp: string;
  enableTracking: boolean;
  trackingId: string | null;
  trackingOffset: string;
  enableAudio: boolean;
  audioOffset: string;
  camspecs: string;
  streamId: string;
  homeTeamColor: string;
  awayTeamColor: string;
  awayTeam: any;
  homeTeam: any;
}

interface AdminFormProps {
  gameDetails: GameDetails;
  handleGameDataChange: (updatedDetails: GameDetails) => void;
  handleClose: () => void;
}

const AdminGameForm: React.FC<AdminFormProps> = ({ gameDetails, handleGameDataChange }) => {
  const [formData, setFormData] = useState<GameDetails>(gameDetails);

  const [trackingIdNull, setTrackingIdNull] = useState<boolean>(formData.trackingId === null);

  const handleChange = (field: keyof GameDetails, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const { homeTeam, awayTeam } = gameDetails;

  const homePrimaryPrimaryTeamColor =
    homeTeam.teamColors?.find((c: any) => c.type === 'primary')?.hex_color || '#000000';

  const homePrimarySecondaryTeamColor =
    homeTeam.teamColors?.find((c: any) => c.type === 'secondary')?.hex_color || '#000000';

  const awayPrimaryPrimaryTeamColor =
    awayTeam.teamColors?.find((c: any) => c.type === 'primary')?.hex_color || '#000000';

  const awayPrimarySecondaryTeamColor =
    awayTeam.teamColors?.find((c: any) => c.type === 'secondary')?.hex_color || '#000000';

  const [homeTeamSecondaryColorEnabled, setHomeTeamSecondaryColorEnabled] =
    useState<boolean>(false);
  const [awayTeamSecondaryColorEnabled, setAwayTeamSecondaryColorEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    handleGameDataChange(formData);
  }, [formData]);

  const handleSetHomeSecondaryColor = () => {
    setHomeTeamSecondaryColorEnabled((prev) => !prev);
    handleChange(
      'homeTeamColor',
      homeTeamSecondaryColorEnabled ? homePrimaryPrimaryTeamColor : homePrimarySecondaryTeamColor,
    );
  };

  const handleSetAwaySecondaryColor = () => {
    setAwayTeamSecondaryColorEnabled((prev) => !prev);
    handleChange(
      'awayTeamColor',
      awayTeamSecondaryColorEnabled ? awayPrimaryPrimaryTeamColor : awayPrimarySecondaryTeamColor,
    );
  };

  return (
    <Box component="div" display="flex" flexDirection="column" gap={2} sx={{ padding: 3 }}>
      <Box display="flex" flexDirection="row" gap={2}>
        {/* First Column */}
        <Box flex="1" display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6">General</Typography>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              variant="outlined"
              value={formData.status}
              onChange={(e) => handleChange('status', e.target.value)}
            >
              <MenuItem value="recorded">Recorded</MenuItem>
              <MenuItem value="live">Live</MenuItem>
              <MenuItem value="scheduled">Scheduled</MenuItem>
            </Select>
          </FormControl>
          <DateTimePicker
            label="Start Time"
            value={new Date(formData.startTimestamp)}
            onChange={(newValue) =>
              handleChange(
                'startTimestamp',
                newValue ? newValue.toISOString() : new Date(formData.startTimestamp),
              )
            }
          />
          <DateTimePicker
            label="End Time"
            value={formData.endTimestamp ? new Date(formData.endTimestamp) : null}
            onChange={(newValue) =>
              handleChange('endTimestamp', newValue ? newValue.toISOString() : null)
            }
          />
          <TextField
            label="Stream ID"
            value={formData.streamId}
            onChange={(e) => handleChange('streamId', e.target.value)}
            fullWidth
            helperText="This is the stream ID for the game."
          />

          <Box display="flex" flexDirection={'column'} gap={1} sx={{ height: '200px' }}>
            <FormControlLabel
              sx={{ m: 1 }}
              control={
                <Switch
                  checked={homeTeamSecondaryColorEnabled}
                  onClick={handleSetHomeSecondaryColor}
                />
              }
              label={`Use ${gameDetails.homeTeam.abbreviation} secondary color for this game`}
            />
            <FormControlLabel
              sx={{ m: 1 }}
              control={
                <Switch
                  checked={awayTeamSecondaryColorEnabled}
                  onClick={handleSetAwaySecondaryColor}
                />
              }
              label={`Use ${gameDetails.awayTeam.abbreviation} secondary color for this game`}
            />

            <GameMatchup game={formData} />
          </Box>
        </Box>

        {/* Second Column */}
        <Box flex="1" display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6">Tracking and Audio</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.enableTracking}
                onChange={(e) => handleChange('enableTracking', e.target.checked)}
              />
            }
            label="Enable Tracking"
          />
          <Box display="flex" alignItems="center" gap={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={trackingIdNull}
                  onChange={(e) => {
                    setTrackingIdNull(e.target.checked);
                    handleChange('trackingId', e.target.checked ? null : '');
                  }}
                />
              }
              label="Set Tracking ID to Null"
            />
            <TextField
              label="Tracking ID"
              value={trackingIdNull ? '' : formData.trackingId || ''}
              onChange={(e) => handleChange('trackingId', e.target.value)}
              fullWidth
              disabled={trackingIdNull}
              helperText="This is the SMT tracking ID for the game"
            />
          </Box>
          <TextField
            label="Tracking Offset"
            value={formData.trackingOffset}
            onChange={(e) => handleChange('trackingOffset', e.target.value)}
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '\\d*' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.enableAudio}
                onChange={(e) => handleChange('enableAudio', e.target.checked)}
              />
            }
            label="Enable Audio"
          />
          <TextField
            label="Audio Offset"
            value={formData.audioOffset}
            onChange={(e) => handleChange('audioOffset', e.target.value)}
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '\\d*' }}
          />
        </Box>

        {/* Third Column */}
        <Box flex="1" display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6">Camera Specifications</Typography>
          <JsonEditor
            data={formData.camspecs}
            setData={(e) => {
              handleChange('camspecs', e);
            }}
            collapse={true}
            theme={githubDarkTheme}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminGameForm;
