export type TagColor = {
  b: string;
  c: string;
};
export function colorTag(str: string): TagColor {
  if (!str) {
    return { b: 'black', c: 'white' };
  }

  const tagString = str?.toLowerCase();

  const materialColors: Array<TagColor> = [
    { b: '#0C0E23', c: 'white' },
    { b: '#4B5494', c: 'white' },
    { b: '#B7B5BD', c: 'black' },
    { b: '#6587A3', c: 'white' },

    { b: '#5c4075', c: 'white' },
    { b: '#05075e', c: 'white' },
    { b: '#27426b', c: 'white' },

    { b: '#03042f', c: 'white' },
    { b: '#2b5485', c: 'white' },
    { b: '#62457a', c: 'white' },
  ];
  // create the hex for the random string
  let hash = 0;
  for (let i = 0; i < tagString.length; i += 1) {
     
    hash = tagString.charCodeAt(i) + ((hash << 5) - hash);
  }

  return materialColors[Math.abs(hash) % materialColors.length];
}
